import Header from './component/header/index.vue';
import Footer from '@/component/back-layout/1.1.5/component/footer/1.0.1/index.vue'
import consumer_menu_getUserMenu from '@/lib/data-service/haolv-default/consumer_menu_getUserMenu'
import get_consumer_menu_getUserMenu from '@/lib/common-service/get_consumer_menu_getUserMenu'
import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info' // 获取个人信息
import centerMenu from "@/component/back-layout/1.1.5/center-menu";
import getMenuData from '@/component/back-layout/1.1.5/menuList'
export default {
    data() {
        return {
            // oriNavList: [],
            // navList: []
        }
    },
    components: {
        Header,
        Footer
    },
    async created() {
        //let oriNavList = getMenuData() // 本地菜单
        //this.$store.commit('setOriNavList', oriNavList)
        await this.getUserMenu() // 请求菜单
        this.getMenuList()
    },
    mounted() {},
    activated() {
    },
    deactivated() { },
    destroyed() {
    },
    watch: {
        $route() {
            this.getMenuList()
        }
    },
    computed: {
        setBg() {
            let routeName = this.$route.name
            if (routeName === 'admin-travel-book-home') {
                // 当前页面为行程预定的首页
                return 'home'
            } else if (routeName === 'admin-train-search' || routeName === 'admin-train-change-search') {
                // 当前页是火车票搜索页
                return 'train'
            } else if (routeName === 'admin-flight-search') {
                // 当前页是机票搜索页
                return 'flight'
            } else if (routeName === 'admin-hotel-search') {
                return 'hotel'
            } else {
                return ''
            }
        },
        navList() {
            return this.$store.state.navList
        },
        oriNavList() {
            return this.$store.state.oriNavList
        },
    },
    filters: {},
    methods: {
        async getUserMenu() {
            function flatten(arr) {
                return [].concat(...arr.map(item => [].concat(item, ...flatten(item.menus || []))))
            }
            let res = await get_consumer_menu_getUserMenu({ clear_cache: true })
            res.datas[0].menus = [] // 删除行程预定里面的
            res.datas.push(centerMenu)
            let oriNavList = JSON.parse(JSON.stringify(res.datas))
            oriNavList = flatten(oriNavList) // 转成1维数组
            /*for(let item of oriNavList) { // 删除menus
                if(item.menus) {
                    delete item.menus
                }
            }*/
            let oriResultNavList = []
            oriNavList.forEach((value, index) => {
                if (value.menus) {
                    delete value.menus
                }
                // 暂时屏蔽功能导航
                if (value.name !== '功能导航') {
                    oriResultNavList.push(value)
                }
            })
            // this.oriNavList = oriResultNavList // 保存到原始菜单数组
            this.$store.commit('setOriNavList', oriResultNavList)
        },
        getMenuList() {
            let navList = JSON.parse(JSON.stringify(this.oriNavList))
            navList = this.setActive(navList)
            // this.navList = this.translateDataToTree(navList)
            navList = this.translateDataToTree(navList)
            this.$store.commit('setNavList', navList)
            // this.setMetaList()
        },
        setActive(dataList) {
            let activeId = ''
            dataList.forEach(value => {
                if (value.metaName === this.$route.meta.lastMenu) {
                    value.active = true
                    activeId = value.parentId
                } else {
                    value.active = false
                }
            })
            let getParentActive = function (parentId) {
                if (parentId === 0) {
                    return
                }
                dataList.forEach(value => {
                    if (value.id === parentId) {
                        value.active = true
                        getParentActive(value.parentId)
                    }
                })
            }
            getParentActive(activeId)

            // 排序
            dataList.sort((a, b) => {
                return (a.orderNum || 999) - (b.orderNum || 999)
            })
            return dataList
        },
        translateDataToTree(data) {
            const parents = data.filter(value => value.id && (value.parentId === '0' || value.parentId === 0 || value.parentId === undefined || value.parentId === null)) // 没有父节点的数据
            const children = data.filter(value => value.id && (value.parentId !== '0' || value.parentId !== 0)) // 有父节点的数据
            // 定义转换方法的具体实现
            const translator = (parents, children) => {
                parents.forEach((parent) => {
                    children.forEach((current, index) => {
                        if (current.parentId === parent.id) {
                            const temp = JSON.parse(JSON.stringify(children))
                            temp.splice(index, 1)
                            translator([current], temp)
                            typeof parent.children !== 'undefined' ? parent.children.push(current) : parent.children = [current]
                        }
                    }
                    )
                }
                )
            }
            translator(parents, children)
            return parents
        },

    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
